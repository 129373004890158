import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from "./router";

import Vant from 'vant';
import 'vant/lib/index.css';

import 'babel-polyfill'

import '@/styles/icon.scss'

// flexible
import "amfe-flexible";

import "@/components"

Vue.config.productionTip = false

Vue.use(Vant)

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
