<template>
  <span class="iot-form-item"
        :class="{'item-arrow-up': popupVisible && itemType === 'picker', 'item-arrow-down': !popupVisible && itemType === 'picker'}">
    <template v-if="itemType === 'slot'">
      <slot v-if="itemSlot"
            :name="itemSlot" />
    </template>
    <template v-else>
      <template v-if="itemType === 'upload'">
        <van-uploader 
          v-model="fileList" 
          :multiple="itemMultiple" 
          :max-count="maxCount" 
          :upload-text="uploadText"
          :preview-size="previewSize"
          :after-read="afterRead"/>
      </template>
      <template v-else>
        <template v-if="itemClickable">
          <div class="iot-form-item-click" @click="handleCkickable">
            <van-field v-model="val"
              :label="itemLabel"
              :placeholder="$t(itemHolder)"
              :is-link="itemIsLink"
              :clickable="false"
              :readonly="true"
              :disabled="itemDisable"
              :class="itemDisableClass"
              :rules="itemRules" />
          </div>
        </template>
        <template v-else>
          <van-field v-model="val"
                    :label="itemLabel"
                    :placeholder="$t(itemHolder)"
                    :is-link="itemIsLink"
                    :readonly="itemReadonly"
                    :disabled="itemDisable"
                    :maxlength="selectConfig.maxlength|| '254'"
                    :class="itemDisableClass"
                    :rules="itemRules"
                    :type="itemInputType || 'text'" />
        </template>
      </template>
    </template>
    <van-popup v-model="popupVisible"
               get-container="body"
               :overlay="true"
               :safe-area-inset-bottom="true"
               position="bottom">
      <template v-if="itemType === 'picker'">
        <van-picker ref="picker"
                    :columns="selectConfig.options || []"
                    :columns-field-names="{
            text: selectConfig.labelName || 'text',
            values: selectConfig.valName || 'values',
            children: selectConfig.childName || 'children',
          }"
                    :value-key="selectConfig.labelName || 'text'"
                    :confirm-button-text="$t(selectConfig.confirmLabel) || $t('button.confirm')"
                    :cancel-button-text="$t(selectConfig.cancelLabel) || $t('button.cancel')"
                    show-toolbar
                    @confirm="onPickerConfirm"
                    @cancel="popupVisible = false" />
      </template>
      <template v-else-if="itemType === 'time'">
        <van-datetime-picker ref="timePicker"
                             v-model="timeVal"
                             :type="selectConfig.pickerType || 'time'"
                             :min-date="selectConfig.minDate"
                             :max-date="selectConfig.maxDate"
                             :confirm-button-text="$t(selectConfig.confirmLabel) || $t('button.confirm')"
                             :cancel-button-text="$t(selectConfig.cancelLabel) || $t('button.cancel')"
                             show-toolbar
                             @confirm="onTimeConfirm"
                             @cancel="popupVisible = false">
          <template slot="columns-top">
            <div class="columns-top">
              <span>{{ $t('label.years') }}</span>
              <span>{{ $t('label.month') }}</span>
              <span>{{ $t('label.day') }}</span>
              <span>{{ $t('label.hours') }}</span>
              <span>{{ $t('label.minutes') }}</span>
            </div>
          </template>
        </van-datetime-picker>
      </template>
    </van-popup>
                     
  </span>
</template>

<script>
import dayjs from 'dayjs'
export default {
  components: {},
  props: {
    itemProp: {
      type: String,
      required: false,
      default: "",
    },
    itemLabel: {
      type: String,
      required: false,
      default: "",
    },
    itemVal: {
      type: [String, Number, Array],
      required: false,
      default: "",
    },
    itemIsLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemInputType: {
      type: String,
      required: false,
      default: "text",
    },
    itemType: {
      type: String,
      required: false,
      default: "",
    },
    itemHolder: {
      type: String,
      required: false,
      default: "",
    },
    selectConfig: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    itemSlot: {
      type: String,
      required: false,
      default: "",
    },
    itemReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemMaxlength: {
      type: String,
      required: false,
      default: "254",
    },
    minNum: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    maxNum: {
      type: [String, Number],
      required: false,
      default: 100,
    },
    itemClickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemRules: {
      type: [Array],
      required: false,
      default: function () {
        return [];
      },
    },
    itemDisableClass: {
      type: String,
      required: false,
      default: '',
    },
    itemMultiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxCount: {
      type: Number,
      required: false,
      default: 1,
    },
    uploadText: {
      type: String,
      required: false,
      default: '',
    },
    previewSize: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      val: null,
      popupVisible: false,
      timeVal: '',
      fileList: []
    };
  },
  watch: {
    val (val, oldVal) {
      if (this.itemType === 'time') {
        this.timeVal = new Date(dayjs(val))
      }
      this.$emit("val-change", this.itemProp, val);
    },
    itemVal (val) {
      this.val = val;
    }
  },
  mounted () {
    if (this.itemVal) {
      this.val = this.itemVal;
    }
  },
  methods: {
    /**
     * @description 点击事件
     */
    handleCkickable () {
      if (this.itemReadonly || this.itemDisable) return
      this.popupVisible = !this.popupVisible;
    },
    /**
     * @description 选择器确认
     */
    onPickerConfirm (value) {
      this.val = this.selectConfig.labelName
        ? value[this.selectConfig.labelName]
        : value["text"];
      this.$emit(
        "val-change",
        this.selectConfig.valueProp,
        this.selectConfig.valName
          ? value[this.selectConfig.valName]
          : value["id"]
      );
      this.$emit("btn-click", this.selectConfig.valueChangeName, value);
      this.popupVisible = false;
    },
    /**
     * @description 时间选择确认
     */
    onTimeConfirm (value) {
      let cur = new Date(value).getTime()
      if (this.selectConfig && this.selectConfig.valueFormat) {
        if (this.selectConfig.valueFormat !== 'timestamp') {
          cur = dayjs(new Date(value)).format(this.selectConfig.valueFormat)
        }
      }
      this.val = cur
      this.popupVisible = false;

    },
    afterRead (file, detail) {
      this.$emit("btn-click", this.selectConfig.valueChangeName, [file, detail]);
    },
  },
};
</script>

<style lang="scss">
.iot-form-item {
  input {
    font-size: 14px;
    z-index: 5;
  }
  .van-field__error-message {
    position: absolute;
    top: 0;
    left: 0;
  }
  .iot-form-item-arrow {
    position: absolute;
    z-index: 10;
    right: 10px;
    top: 50px;
  }
  &.item-arrow-up,
  &.item-arrow-down {
    .van-field {
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 20px auto;
    }
  }
  &.item-arrow-up {
    .van-icon {
      transform: rotate(270deg);
      transition: 500ms;
    }
  }
  &.item-arrow-down {
    .van-icon {
      transform: rotate(90deg);
      transition: 500ms;
    }
  }
  .iot-form-item-click {
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      opacity: 0;
    }
  }
}
.columns-top {
  display: flex;
  width: 100%;
  color: #000;
  font-size: 16px;
  z-index: 5;
  margin-top: 13px;
  & > span {
    flex: 1;
    text-align: center;
  }
}
.van-cascader__tabs{
    .van-tab--active{
      .van-tab__text{
        color: #0073e6;
      }
    }
    .van-tabs__line{
      display: none;
    }
  }
</style>
