<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    // 禁止触摸滚动
    // document.body.addEventListener(
    //   "touchmove",
    //   function (e) {
    //     e.preventDefault();
    //   },
    //   { passive: false }
    // );
  }
}
</script>

<style lang="scss">
@import '~@/assets/style/public-class.scss';
#app {
  width: 100%;
  min-height: 100%;
  height: 100%;
}
</style>
