import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: 'index' }
  },
  {
    path: '/index/:id/:type',
    name: 'index',
    component: () => import('../view/index/index.vue')
  },
  {
    path: '/invite/:id/:type',
    name: 'invite',
    component: () => import('../view/invite/index.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('../view/deskeeper-map/index.vue')
  },
  {
    path: '/fault',
    name: 'fault',
    component: () => import('../view/fault/index.vue'),
    meta: {
      title: "Meeting Smart"//对应页面标题
    }
  },
  {
    path: '/talk',
    name: 'talk',
    component: () => import('../view/ding/index.vue')
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {//实现替换当前页面标题
  if (to.meta.title) {//判断是否有标题
    document.title = to.meta.title
  }
  next()//执行进入路由，如果不写就不会进入目标页
})

export default router;
