<template>
  <van-form
    ref="iotForm"
    class="iot-form"
    :readonly="formReadonly"
    :abel-width="formLabelPosition === 'top' ? 0 : formLabelWidth"
    :label-align="formLabelAlign"
    :scroll-to-error="false"
    :validate-first="true"
    :show-error-message="false"
    @submit="onSubmit"
    @failed="onFailed"
  >
    <div class="iot-form-main wrapper" ref="formMain">
      <div class="content">
        <template v-for="(x, i) in formItems">
          <div
            v-if="!x.hidden"
            class="iot-form-item-box"
            :key="i" 
            :class="x.class || ''"
            :style="x.style || {}"
          >
            <template v-if="formLabelPosition === 'top' && x.label && x.label != ''">
              <div class="iot-form-item-label" :class="{'iot-form-item-label-grey': x.readonly || x.disable}">{{ $t(x.label) }}</div>
            </template>
            <template v-if="x.otherSlot">
              <slot :name="x.otherSlot" :data="x" />
            </template>
            <iot-form-item
              :class="{'iot-form-item-grey': x.readonly || x.disable}"
              :style="x.style || {}"
              :item-label="formLabelPosition === 'top' ? '' : $t(x.label)"
              :item-val="formData[x.prop]"
              :item-type="x.type || ''"
              :item-input-type="x.inputType || 'text'"
              :item-prop="x.prop"
              :item-holder="x.placeholder"
              :item-slot="x.slot || ''"
              :item-is-link="x.isLink ? x.isLink : false"
              :item-readonly="x.readonly ? x.readonly : false"
              :item-disable="x.disable ? x.disable : false"
              :item-disable-class="x.disableClass ? x.disableClass : ''"
              :item-clickable="x.clickable"
              :select-config="x.selectConfig || {}"
              :item-maxlength="x.maxlength || '254'"
              :min-num="x.minNum || 0"
              :max-num="x.maxNum || 100"
              :item-rules="formRules[x.prop] || []"
              :item-multiple="x.multiple"
              :max-count="x.maxCount"
              :upload-text="x.uploadText"
              :preview-size="x.previewSize"
              @val-change="valChange"
              @btn-click="operaEvent"
            >
              <template v-if="x.slot" :slot="x.slot">
                <slot :name="x.slot" />
              </template>
            </iot-form-item>
          </div>
        </template>
      </div>
    </div>
    <div class="iot-form-btn">
      <template v-if="btnOpera.find(x=>x.type ==='slot')">
        <template v-if="!btnOpera.find(x=>x.type ==='slot').hidden">
          <slot :name="btnOpera.find(x=>x.type ==='slot').slot" />
        </template>
      </template>
      <div class="iot-form-btn-btn">
        <template v-for="(x, i) in btnOpera">
          <template v-if="x.type !=='slot'" >
              <div class="iot-form-btn-box" :key="i">
                <template v-if="x.nativeType === 'submit'">
                  <van-button
                    :round="x.round"
                    :block="x.block"
                    :type="x.type || 'primary'"
                    :size="x.size || 'small '"
                    :native-type="x.nativeType"
                    :disabled="x.disabled || false">{{ $t(x.label) }}</van-button>
                </template>
                <template v-else-if="x.type === 'default'">
                  <div class="iot-form-btn-button" @click="operaEvent(x.eventName)">{{ $t(x.label) }}</div>
                </template>
                <template v-else>
                  <van-button
                    :round="x.round"
                    :block="x.block"
                    :type="x.type || 'primary'"
                    :size="x.size || 'small '"
                    :disabled="x.disabled || false"
                    native-type='button'
                    @click="operaEvent(x.eventName)">{{ $t(x.label) }}</van-button>
                </template>
              </div>
          </template>
        </template>
      </div>
    </div>
  </van-form>
</template>

<script>
import BScroll from 'better-scroll'
import IotFormItem from "./components/iot-form-item";
export default {
  components: {
    IotFormItem,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    formReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    formLabelPosition: {
      type: String,
      required: false,
      default: "left",
    },
    formLabelWidth: {
      type: [Number, String],
      required: false,
      default: "100px",
    },
    formLabelAlign: {
      type: String,
      required: false,
      default: "left",
    },
    formConfig: {
      type: Object,
      required: false,
      default: () => {
        return {
          asterisk: true,
          labelPosition: "left",
          labelWidth: "90px",
          size: "small",
        };
      },
    },
    formItems: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    btnOpera: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    formRules: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      scroll: null,
      scrollView: false
    };
  },
  watch: {
    formItems() {
      this.$nextTick(() => {
        this.scroll.refresh()
      })
    }
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * @description 初始化
     */
    init() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.formMain, {
            scrollY: true,
            click: true
          })
        } else {
          this.scroll.refresh();
        }
      })
    },
    /**
     * @description 按钮点击事件
     * @param { String } name
     */
    operaEvent: function (eventName, info, valid) {
      if (!eventName) return;
      this.$emit("btn-click", eventName, info);
    },
    /**
     * @description form提交  验证通过
     */
    onSubmit() {
      this.$emit("btn-click", "onSubmit");
    },
    /**
     * @description form提交  验证不通过
     */
    onFailed(error) {
      this.$toast.fail(this.$t(error.errors[0].message));
      console.log(error)
    },
    /**
     * @description
     */
    valChange(prop, info) {
      if (!prop) return
      this.formData[prop] = info;
    }
  },
};
</script>

<style lang="scss">
.iot-form {
  width: 100%;
  height: 100%;
  .iot-form-main {
    width: 100%;
    position: relative;
    height: calc(100% - 92px);
    overflow: hidden;
    padding: 0 38px;
    .content {
      width: 100%;
      position: relative;
      left: 0;
      top: 0;
    }
    .iot-form-item-label-grey {
      border-color: #999;
    }
    .iot-form-item-grey {
      input {
        color: #999;
        border-color: #999;
      }
    }
    .van-field--error {
      border-color: #ee0a24 !important;
    }
  }
  .iot-form-item-box {
    position: relative;
    .iot-form-item-label {
      position: relative;
      color: #646566;
      font-size: 18px;
      line-height: 0.64rem;
      padding: 10px 16px;
    }
  }
  .iot-form-btn {
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    .iot-form-btn-btn{
      display: flex;
      justify-content: space-between;
      .iot-form-btn-box {
        flex-grow: 1;
        margin: 8px 20px 0 0;
        &:first-child{
          margin-left: 20px;
        }
      }
      .iot-form-btn-button{
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        border: 1px solid #0073e6;
        color: #0073e6;
        border-radius: 27px;
        padding: 0 16px;
      }
    }
  }
  .main-bottom {
    width: 100%;
    height: 100px;
  }
}
</style>
